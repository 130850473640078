/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import reactWhite from 'assets/img/brand/argon-react-white.png';
import argonReact from 'assets/img/brand/argon-react.png';

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: '',
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: 'collapsing-out',
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: '',
    });
  };

  render() {
    return (
      <>
        <header className='header-global'>
          <Navbar
            className='navbar-main navbar-transparent navbar-light headroom'
            expand='lg'
            id='navbar-main'
          >
            <Container>
              <NavbarBrand className='mr-lg-2' to='/' tag={Link}>
                <img alt='...' src={reactWhite} style={{width:130, height:80}} />
              </NavbarBrand>
              <button className='navbar-toggler' id='navbar_global'>
                <span className='navbar-toggler-icon' />
              </button>
              <UncontrolledCollapse
                toggler='#navbar_global'
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className='navbar-collapse-header'>
                  <Row>
                    <Col className='collapse-brand' xs='6'>
                      <Link to='/'>
                        <img alt='...' src={argonReact} />
                      </Link>
                    </Col>
                    <Col className='collapse-close' xs='6'>
                      <button className='navbar-toggler' id='navbar_global'>
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className='navbar-nav-hover align-items-lg-center' navbar>
                  <UncontrolledDropdown nav>
                    <DropdownMenu className='dropdown-menu-xl'>
                      <div className='dropdown-menu-inner'>
                        <Media
                          className='d-flex align-items-center'
                          href='https://demos.creative-tim.com/argon-design-system-react/#/documentation/overview?ref=adsr-navbar'
                          target='_blank'
                        >
                          <div className='icon icon-shape bg-gradient-primary rounded-circle text-white'>
                            <i className='ni ni-spaceship' />
                          </div>
                          <Media body className='ml-3'>
                            <h6 className='heading text-primary mb-md-1'>
                              Getting started
                            </h6>
                            <p className='description d-none d-md-inline-block mb-0'>
                              Learn how to use Argon compiling Scss, change
                              brand colors and more.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className='d-flex align-items-center'
                          href='https://demos.creative-tim.com/argon-design-system-react/#/documentation/colors?ref=adsr-navbar'
                          target='_blank'
                        >
                          <div className='icon icon-shape bg-gradient-success rounded-circle text-white'>
                            <i className='ni ni-palette' />
                          </div>
                          <Media body className='ml-3'>
                            <h6 className='heading text-primary mb-md-1'>
                              Foundation
                            </h6>
                            <p className='description d-none d-md-inline-block mb-0'>
                              Learn more about colors, typography, icons and the
                              grid system we used for Argon.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className='d-flex align-items-center'
                          href='https://demos.creative-tim.com/argon-design-system-react/#/documentation/alert?ref=adsr-navbar'
                          target='_blank'
                        >
                          <div className='icon icon-shape bg-gradient-warning rounded-circle text-white'>
                            <i className='ni ni-ui-04' />
                          </div>
                          <Media body className='ml-3'>
                            <h5 className='heading text-warning mb-md-1'>
                              Components
                            </h5>
                            <p className='description d-none d-md-inline-block mb-0'>
                              Browse our 50 beautiful handcrafted components
                              offered in the Free version.
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className='ni ni-collection d-lg-none mr-1' />
                      <span className='nav-link-inner--text'>Menu</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to='/landing-page' tag={Link}>
                        My Work
                      </DropdownItem>
                      <DropdownItem to='/mecha-page' tag={Link}>
                        Achievements
                      </DropdownItem>
                      <DropdownItem to='/profile-page' tag={Link}>
                        Profile
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className='align-items-lg-center ml-lg-auto' navbar>
                  <NavItem>
                    <NavLink
                      className='nav-link-icon'
                      href='https://www.facebook.com/jadabuawwad/'
                      id='tooltip333589074'
                      target='_blank'
                    >
                      <i className='fa fa-facebook-square' />
                      <span className='nav-link-inner--text d-lg-none ml-2'>
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target='tooltip333589074'>
                      Like us on Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className='nav-link-icon'
                      href='https://www.instagram.com/jad.abuawwad/'
                      id='tooltip356693867'
                      target='_blank'
                    >
                      <i className='fa fa-instagram' />
                      <span className='nav-link-inner--text d-lg-none ml-2'>
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target='tooltip356693867'>
                      Follow us on Instagram
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className='nav-link-icon'
                      href='https://twitter.com/jadaboawwad'
                      id='tooltip184698705'
                      target='_blank'
                    >
                      <i className='fa fa-twitter-square' />
                      <span className='nav-link-inner--text d-lg-none ml-2'>
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target='tooltip184698705'>
                      Follow us on Twitter
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className='nav-link-icon'
                      href='https://github.com/jadaboawwad'
                      id='tooltip112445449'
                      target='_blank'
                    >
                      <i className='fa fa-github' />
                      <span className='nav-link-inner--text d-lg-none ml-2'>
                        Github
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target='tooltip112445449'>
                      Star Me on Github
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem className='d-none d-lg-block ml-lg-4'></NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
